<template>
  <section>
    <b-overlay
      id="overlay-background"
      :show="loading"
      variant="transparent"
      opacity="1"
      blur="2px"
      rounded="sm"
    >
      <b-card>
        <b-card-body>
          <b-card-title>
            Opções de Seguidores
          </b-card-title>
        </b-card-body>
        <b-row>
          <b-col
            md="3"
            sm="4"
            class="my-1"
          >
            <b-form-group class="mb-0">
              <label
                class="d-inline-block text-sm-left mr-50"
              >Por Página</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-25"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="5"
            sm="8"
            class="my-1"
          >
            <b-form-group
              label="Ordenar Por"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="sortBySelect"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                  class="w-75"
                >
                  <template v-slot:first>
                    <option value="">
                      -- Nenhum --
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                  class="w-25"
                >
                  <option :value="false">
                    Asc
                  </option>
                  <option :value="true">
                    Desc
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            class="my-1"
          >
            <b-form-group
              label="Buscar"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Escreva o que deseja pesquisar."
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Limpar
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-table
              striped
              hover
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template #cell(name)="data">
                <span v-html="data.item.name" />
              </template>

              <template #cell(price_per_thousand)="data">
                <span>
                  {{ data.item.price_per_thousand | currency }}
                </span>
              </template>

              <template #cell(description)="data">
                <b-button
                  variant="relief-primary"
                  size="sm"
                  @click="showModal(data.item.description)"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
              </template>
            </b-table>
          </b-col>

          <b-col cols="12">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-card>

      <b-modal
        v-if="descriptionModal"
        v-model="descriptionModal"
        hide-footer
        centered
        size="lg"
      >
        <pre
          style="overflow: hidden; white-space: pre-wrap; font-size: 14px"
          v-html="descriptionText"
        />
      </b-modal>

    </b-overlay>
  </section>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardTitle,
  BTable,
  BButton,
  BModal,
  VBTooltip,
  BSpinner
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import TableMixin from './TableMixin'
import IndexMixin from './IndexMixin'
import currencyPrecision from '@/libs/currencyPrecision'

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BTable,
    BButton,
    BModal,
    BSpinner
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  filters: {
    currency (val) {
      return currencyPrecision(val, 2, 6)
    }
  },
  mixins: [TableMixin, IndexMixin],
  data: () => ({
    descriptionModal: false,
    descriptionText: '',
    fields: [
      {
        key: 'id',
        label: 'ID',
        sortable: true
      },
      {
        key: 'name',
        label: 'Serviço',
        sortable: true
      },
      {
        key: 'price_per_thousand',
        label: 'Valor por Mil',
        sortable: true
      },
      {
        key: 'min',
        label: 'min',
        sortable: true
      },
      {
        key: 'max',
        label: 'max',
        sortable: true
      },
      {
        key: 'description',
        label: 'Descrição'
      }
    ]
  }),
  methods: {
    showModal (description) {
      this.descriptionText = description
      this.descriptionModal = true
    }
  }
}
</script>

<style lang="scss" scoped>
pre {
  min-height: 295px;
  padding: 1.5rem;
  margin-bottom: 0;
  border-radius: 0.5rem;
}
</style>
