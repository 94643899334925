export default {
  data: () => ({
    loading: true,
    fields: [
      {
        key: 'service',
        label: 'Tipo'
      },
      {
        key: 'name',
        label: 'nome',
        sortable: true
      },
      {
        key: 'stock',
        label: 'Estoque',
        sortable: true
      },
      {
        key: 'value',
        label: 'Valor',
        sortable: true
      }
    ],
    items: []
  }),
  methods: {
    isVPS (item) {
      return ['Básico', 'Personalizada', 'Avançado'].includes(item.name)
    },
    async getData () {
      const url = '/user/stock'
      await this.$http.get(url)
        .then(res => {
          this.items = res.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    getStockData (item) {
      const response = {
        stock: 0,
        color: 'relief-primary'
      }

      if (item.stock === 0) {
        response.stock = 0
        response.color = ''
      }

      if (item.stock >= 6 && item.stock <= 10 && !this.isVPS(item)) {
        response.stock = item.stock
        response.color = 'relief-warning'
      }

      if (item.stock >= 11 && !this.isVPS(item)) {
        response.stock = item.stock
        response.color = 'relief-success'
      }

      if (item.stock >= 1 && item.stock <= 5 && !this.isVPS(item)) {
        response.stock = item.stock
        response.color = 'relief-danger'
      }

      if (item.stock === 1 && this.isVPS(item)) {
        response.stock = item.stock
        response.color = 'relief-danger'
      }

      if (item.stock === 2 && this.isVPS(item)) {
        response.stock = item.stock
        response.color = 'relief-warning'
      }

      if (item.stock >= 3 && this.isVPS(item)) {
        response.stock = item.stock
        response.color = 'relief-success'
      }

      return response
    }
  },
  async mounted () {
    await this.getData()
  }
}
