<template>
  <section>
    <b-overlay
      id="overlay-background"
      :show="loading"
      variant="transparent"
      opacity="1"
      blur="2px"
      rounded="sm"
    >
      <b-card>
        <b-card-body>
          <b-card-title>
            Estoque
          </b-card-title>

          <b-row>
            <b-col
              md="4"
              class="my-1"
            />
            <b-col
              md="4"
              class="my-1"
            />
            <b-col
              md="4"
              class="my-1"
            >
              <b-form-group
                label="Buscar"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Escreva o que deseja pesquisar."
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                    >
                      Limpar
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

        </b-card-body>
        <b-row>
          <b-col cols="12">
            <b-table
              striped
              hover
              small
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template #cell(stock)="data">
                <b-button
                  size="sm"
                  :variant="getStockData(data.item).color"
                >
                  {{ getStockData(data.item).stock }}
                </b-button>
              </template>

              <template #cell(value)="data">
                {{ data.item.value | currency }}
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </section>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BAlert,
  BCardTitle,
  BTable,
  BButton
} from 'bootstrap-vue'
import IndexMixin from './mixins/IndexMixin'
import TableMixin from './mixins/TableMixin'
import currencyPrecision from '@/libs/currencyPrecision'

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BAlert,
    BCardTitle,
    BTable,
    BButton
  },
  filters: {
    currency (val) {
      return currencyPrecision(val, 2, 2)
    }
  },
  mixins: [IndexMixin, TableMixin]
}
</script>
