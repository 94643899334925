<template>
  <b-card>
    <b-card-title>
      <div class="float-left">
        <b-link class="brand-logo">
          <b-img
            v-if="skin !== 'dark'"
            width="230px"
            src="@/assets/images/logo-text-black.png"
          />
          <b-img
            v-else
            width="230px"
            src="@/assets/images/logo-text-white.png"
          />
        </b-link>
      </div>

      <div class="float-right">
        <dark-Toggler class="d-none d-lg-block float-left mr-1" />

        <b-button
          class="mr-1"
          variant="relief-primary"
          @click.stop="goTo('/#/comprar_servicos')"
        >
          Comprar
        </b-button>

        <b-button
          class="mr-1"
          variant="relief-primary"
          @click.stop="goTo('https://docs.contasinfinity.com.br/')"
        >
          API
        </b-button>

        <b-button
          v-if="!userIsLogged"
          class="mr-1"
          variant="relief-primary"
          @click.stop="goTo('/#/register')"
        >
          Cadastrar
        </b-button>

        <b-button
          v-if="!userIsLogged"
          class="mr-1"
          variant="relief-primary"
          @click.stop="goTo('/#/login')"
        >
          Logar
        </b-button>

        <b-button
          v-if="userIsLogged"
          class="mr-1"
          variant="relief-primary"
          @click.stop="goTo('/#/')"
        >
          Voltar para o Painel
        </b-button>
      </div>
    </b-card-title>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BRow, BCol, BImg, BLink, BButton
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import store from '@/store/index'

export default {
  components: {
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BImg,
    BLink,
    BButton,
    DarkToggler
  },
  computed: {
    skin () {
      return store.state.appConfig.layout.skin
    },
    userIsLogged () {
      return localStorage.getItem('userData') !== null
    }
  },
  methods: {
    goTo (url) {
      window.open(url)
    }
  }
}
</script>
