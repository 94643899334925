export default {
  data: () => ({
    loading: true,
    item: {}
  }),
  async mounted () {
    await this.getData()
  },
  methods: {
    async getData () {
      const url = '/v1/sms/list_of_sms'
      await this.$http
        .get(url)
        .then(res => {
          this.items = res.data
        })
        .finally(() => {
          this.loading = false
          this.totalRows = this.items.length
        })
    }
  }
}
