<template>
  <section>
    <Nav />

    <b-tabs
      v-model="tabIndex"
      class="p-4"
      content-class="mt-1"
      align="center"
    >
      <b-tab title="SMM (Seguidores ~ Curtidas ~ Visualizações)">
        <Smm />
      </b-tab>

      <b-tab title="SMS">
        <Sms />
      </b-tab>

      <b-tab title="Estoque">
        <Stock />
      </b-tab>
    </b-tabs>

  </section>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BCardBody,
  BRow,
  BCol
} from 'bootstrap-vue'
import Smm from './Smm/Index.vue'
import Sms from './Sms/Index.vue'
import Stock from '@/views/pages/Stock/Index.vue'
import Nav from './Nav.vue'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    BCardBody,
    BRow,
    BCol,
    Smm,
    Sms,
    Stock,
    Nav
  },
  data: () => ({
    tabIndex: 0
  }),
  mounted () {
    setTimeout(() => {
      this.tabIndex = parseInt(this.$router.currentRoute.query.tab)
    }, 1000)
  }
}
</script>
